exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-2-0-embed-[id]-js": () => import("./../../../src/templates/2.0/embed/[id].js" /* webpackChunkName: "component---src-templates-2-0-embed-[id]-js" */),
  "component---src-templates-2-0-events-js": () => import("./../../../src/templates/2.0/events.js" /* webpackChunkName: "component---src-templates-2-0-events-js" */),
  "component---src-templates-2-0-events-reservation-[id]-js": () => import("./../../../src/templates/2.0/events/reservation/[id].js" /* webpackChunkName: "component---src-templates-2-0-events-reservation-[id]-js" */),
  "component---src-templates-2-0-events-view-[id]-js": () => import("./../../../src/templates/2.0/events/view/[id].js" /* webpackChunkName: "component---src-templates-2-0-events-view-[id]-js" */),
  "component---src-templates-2-0-index-js": () => import("./../../../src/templates/2.0/index.js" /* webpackChunkName: "component---src-templates-2-0-index-js" */),
  "component---src-templates-2-0-invite-[id]-js": () => import("./../../../src/templates/2.0/invite/[id].js" /* webpackChunkName: "component---src-templates-2-0-invite-[id]-js" */),
  "component---src-templates-2-0-login-js": () => import("./../../../src/templates/2.0/login.js" /* webpackChunkName: "component---src-templates-2-0-login-js" */),
  "component---src-templates-2-0-rinvite-[id]-js": () => import("./../../../src/templates/2.0/rinvite/[id].js" /* webpackChunkName: "component---src-templates-2-0-rinvite-[id]-js" */)
}

